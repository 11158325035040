import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.css']
})
export class FavoriteComponent implements OnInit {
  @Input('isActive') isActivated = true;
  @Input() num = 1;
  constructor() {}

  ngOnInit() {}
  changeclass() {
    this.isActivated = !this.isActivated;
  }
}
