import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-pw",
  templateUrl: "./pw.component.html",
  styleUrls: ["./pw.component.css"]
})
export class PwComponent implements OnInit {
  constructor() {}
  length = 0;
  includeLetters = true;
  password = "";
  includeNumbers = false;
  includeSymbols = false;
  icludeUppercaseLetters = false;

  onChangelenght(value: string) {
    // tslint:disable-next-line: radix
    const parsedValue = parseInt(value);

    if (!isNaN(parsedValue)) {
      this.length = parsedValue;
    }
  }

  onChangeUseLetters() {
    this.includeLetters = !this.includeLetters;
  }
  onChangeUseUppercaseLetters() {
    this.icludeUppercaseLetters = !this.icludeUppercaseLetters;
  }
  onChangeUseNumbers() {
    this.includeNumbers = !this.includeNumbers;
  }
  onChangeUseSymbols() {
    this.includeSymbols = !this.includeSymbols;
  }

  onButtonClick() {
    const numbers = "1234567890";
    const letters = "abcdefghijklmnopqrstuvwxyz";
    const upperLetter = "ABCDEFGHIGKLMNOPQRSTUVWXYZ";
    const symbols = "!@#$%^&*()";

    let validChars = "";
    if (this.includeLetters) {
      validChars += letters;
    }
    if (this.icludeUppercaseLetters) {
      validChars += upperLetter;
    }
    if (this.includeNumbers) {
      validChars += numbers;
    }
    if (this.includeSymbols) {
      validChars += symbols;
    }

    let generatedPassword = "";
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.length; i++) {
      const index = Math.floor(Math.random() * validChars.length);
      generatedPassword += validChars[index];
    }

    this.password = generatedPassword;
  }
  ngOnInit() {}
}
