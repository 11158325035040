import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormsModule, Validators } from '@angular/forms';
import { SpaceValidator } from '../common/validators/space.validators';

@Component({
  selector: 'app-react-form',
  templateUrl: './react-form.component.html',
  styleUrls: ['./react-form.component.css']
})
export class ReactFormComponent {
  form = new FormGroup({
    // میشه برای دسته بندی فرم نیز یک فرمگروپ مثل زیر اضافه کرد و درون آن چند فرم کنترل گذاشت
    // secction : new FormGroup({
    //   some formControl
    // }),
    userName: new FormControl('', [Validators.required,
      Validators.minLength(3),
      SpaceValidator.cannotContainSpace],
      SpaceValidator.userValidation),
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
     secction : new FormGroup({
      email: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required)
      }),
  });

  login() {
    this.form.setErrors({
      invalidLogin: true
    });
    console.log(this.form);
  }

  get username() {
    return this.form.get('userName');
  }

  get password() {
    return this.form.get('password');
  }
  constructor() {}
}
