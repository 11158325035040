import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appCustomDirective]'
})
export class CustomDirectiveDirective {
  @Input('format') format;

  constructor(private el: ElementRef) {}

  @HostListener('focus') onfocus() {
    console.log('textfocused');
  }

  @HostListener('keypress') onkeypress() {
    console.log('Key press');
    const value: string = this.el.nativeElement.value;
    if (this.format === 'upperCase') {
      this.el.nativeElement.value = value.toUpperCase();
    } else {
    this.el.nativeElement.value = value.toLowerCase();
    }
  }

  @HostListener('blur') onblur() {
    console.log('textblur');
    const value: string = this.el.nativeElement.value;
    if (this.format === 'upperCase') {
      this.el.nativeElement.value = value.toUpperCase();
    } else {
    this.el.nativeElement.value = value.toLowerCase();
    }
  }
}
