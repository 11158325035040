import { FormGroup, FormControl, FormsModule, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  form = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl(),
    email: new FormControl(),
    phone: new FormControl()
  });


  // برای اینکه به فیلدها در فایل تمپلیت دسترسی داشته باشیم باید متدهای زیر را بنویسیم
  get firstName() {
    return this.form.get('firstName');
  }
  get lastName() {
    return this.form.get('lastName');
  }
  get email() {
    return this.form.get('email');
  }
  get phone() {
    return this.form.get('phone');
  }
  log(log) {
    console.log(log);
  }

  constructor() {}

  ngOnInit() {}
}
