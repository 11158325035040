import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-form',
  templateUrl: './test-form.component.html',
  styleUrls: ['./test-form.component.css']
})
export class TestFormComponent {
  constructor() {}

  methods = [
   { id: 1, method: 'Post' },
   { id: 2, method: 'Taxi' }];

  log(m) {
    console.log(m);
  }

  submit(f) {
    console.log('نتیجه های خروجی فرم');
    console.log(f.value);
    console.log(f.valid);
    console.log(f.value.secion1);
    console.log(f.value.secion1.exampleInputEmail1);
    console.log(f.value.contactMethod);
  }
}
