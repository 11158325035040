interface CourseData {
  Cname: string;
  Cduration: number;
}

export class CourseServises {
  courses: CourseData[] = [
    { Cname: 'co1', Cduration: 10 },
     { Cname: 'co2', Cduration: 5 },
      { Cname: 'co3', Cduration: 8 },
      { Cname: 'co4', Cduration: 26 },
      { Cname: 'co5', Cduration: 90 },
      { Cname: 'co6', Cduration: 889 },
      { Cname: 'co7', Cduration: 78 },
    ];

  getdata() {
    return this.courses;
  }
}
