import { Pipe, PipeTransform } from '@angular/core';
import { pipe } from 'rxjs';

@Pipe({
  name: 'summary'
})
export class SummaryPipe implements PipeTransform {
  transform(value: string, limits?: number) {
    if (!value) {
      return null;
    }
    if (!limits) {
      limits = 17;
    }


    return value.substr(0, limits) + '...';
  }
}
