import { Component, OnInit } from '@angular/core';
import { CourseDIServises } from './courseDI-services';

// وارد کردن کلاس به یک کلاس دیگر به صورت tightly coupled

@Component({
  selector: 'app-course-di',
  templateUrl: './courseDI.component.html',
  styleUrls: ['./courseDI.component.css']
})
export class CourseDIComponent {
  Courses;

  constructor(Servise: CourseDIServises) {
    this.Courses = Servise.getdata();
  }

  title = 'Courses List with Loosly coupled';


}
