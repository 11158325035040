import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { CourseComponent } from './course/course.component';
import { CourseDIComponent } from './courseDI/courseDI.component';
import { CourseDIServises } from './courseDI/courseDI-services';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesComponent } from './pipes/pipes.component';
import { SummaryPipe } from './pipes/Summary.Pipe';
import { FavoriteComponent } from './favorite/favorite.component';
import { DirctiveExampleComponent } from './dirctive-example/dirctive-example.component';
import { CustomDirectiveDirective } from './custom-directive.directive';
import { TestFormComponent } from './test-form/test-form.component';
import { ReactFormComponent } from './react-form/react-form.component';
import { PostComponent } from './post/post.component';
import { PostsService } from './services/posts.service';
import { SignUpComponent } from './sign-up/sign-up.component';
import { PwComponent } from './Password-generator/pw/pw.component';

@NgModule({
  declarations: [
    AppComponent,
    CourseComponent,
    CourseDIComponent,
    PipesComponent,
    SummaryPipe,
    FavoriteComponent,
    DirctiveExampleComponent,
    CustomDirectiveDirective,
    TestFormComponent,
    ReactFormComponent,
    PostComponent,
    SignUpComponent,
    PwComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

  ],
  providers: [
    PostsService,
    CourseDIServises,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
