import { AbstractControl, ValidationErrors } from "@angular/forms";

export class SpaceValidator {
  // متد ولید کردن فرم
  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf(" ") >= 0) {
      // // می توان در اینجا یک کامپلکس برگرداند و یا به یک غلط بسنده کرد
      // return {
      //   minlenght: {
      //     requierdLenght: 10,
      //     actualLenght: control.value.length
      //   }};
      return { cannotContainSpace: true };
    }
    return null;
  }
  // async validation
  // شبیه سازی فراخوانی از سرور(در صورتی که یوزر تکراری باشد ارور می دهد)
  static userValidation(
    control: AbstractControl
  ): Promise<ValidationErrors | null> {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (control.value === "mohamad") {
          resolve({ userValidation: true });
        } else {
          resolve(null);
        }
      }, 2000);
    });
  }
}
