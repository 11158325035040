import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pipes',
  templateUrl: './pipes.component.html',
  styleUrls: ['./pipes.component.css']
})
export class PipesComponent {
  constructor() {}

  title = 'mohamad vahabian ghamsari in the kashan';
  rating = 4.23568;
  qty = 32659865236;
  price = 36523658.2236554;
  RDate = new Date(2016, 3, 10);
}
