import { PostsService } from "./../services/posts.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-post",
  templateUrl: "./post.component.html"
})
export class PostComponent implements OnInit {
  posts;
  show = true;

  constructor(private service: PostsService) {}

  ngOnInit() {
    this.service.getPosts().subscribe(
      response => {
        this.posts = response;
      },
      error => {
        this.show = false;
        alert("server was not connected");
        console.log("server error");
      }
    );
  }

  add(input: HTMLInputElement) {
    const post = { title: input.value };
    this.service.creatPost(post).subscribe(
      responce => {
        this.posts.splice(0, 0, post);
        input.value = "";
        console.log(responce);
      },
      (error: Response) => {
        if (error.status === 400) {
          // this.form.setErrors(error);
        } else {
          alert("server was not connected");
          console.log("server error");
        }
      }
    );
  }

  updatePost(post) {
    this.service.updatePost(post.id, post).subscribe(
      Response => {
        console.log(post);
      },
      error => {
        alert("server was not connected");
        console.log("server error");
      }
    );
    console.log("sent will done by put");

    // this.http.patch(this.url + '/' + post.id, JSON.stringify({title: 'mvm'}))
    // .subscribe(Response => {
    //   console.log(post);
    // });
    // console.log('sent will done by patch');
  }

  DeletePost(post) {
    this.service.deletePost(post.id).subscribe(
      responce => {
        const index = this.posts.indexof(post);
        this.posts.splice(index, 1);
      },
      (error: Response) => {
        if (error.status === 404) {
          alert("selected posts was deleted already");
          console.log(error);
        } else {
          alert("server was not connected");
          console.log("server error");
        }
      }
    );
  }
}
