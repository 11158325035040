import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'MVMApp';
  rate = {
    isactive: false
  };
  num2 = 2;
  num3 = 3;
  num4 = 4;
  num5 = 5;
}
