import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppError } from "../common/app.errore";

@Injectable({
  providedIn: "root"
})
export class PostsService {
  url = "https://jsonplaceholder.typicode.com/posts";

  constructor(private http: HttpClient) {}

  getPosts() {
    return this.http.get(this.url);
  }
  creatPost(post) {
    return this.http.post(this.url, post);
  }
  updatePost(id: number, post) {
    return this.http.put(this.url + "/" + id, post);
  }
  deletePost(id: number) {
    return this.http.delete(this.url + "/" + id);
  }
}
