import { Component, OnInit } from '@angular/core';
import { CourseDIServises, CourseData } from '../courseDI/courseDI-services';

@Component({
  selector: 'app-dirctive-example',
  templateUrl: './dirctive-example.component.html',
  styleUrls: ['./dirctive-example.component.css']
})
export class DirctiveExampleComponent implements OnInit {
  Courses;
  kk;
  isblue = false;
  isEdit = false;
  textValue;

  constructor(private Servise: CourseDIServises) {
    this.Courses = Servise.getdata();
    this.kk = Object.keys(this.Courses).length;
  }

  OnEdite(m: CourseData) {
    m.situation = !m.situation;
    // this.textValue = m.Cname;
  }
  OnConfirm(m: CourseData) {
    m.Cname = this.textValue;
    m.situation = !m.situation;
    this.textValue = null;
  }

  ngOnInit() {}
}
