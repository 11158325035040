export interface CourseData {
  id: number;
  Cname: string;
  Cduration: number;
  situation: boolean;
}

export class CourseDIServises {
  // courses: CourseData;

  courses: CourseData[] = [
    {id: 1, Cname: 'co1', Cduration: 10 , situation: false},
     {id: 2, Cname: 'co2', Cduration: 5 , situation: false},
      {id: 3, Cname: 'co3', Cduration: 8 , situation: false},
      {id: 4, Cname: 'co4', Cduration: 26 , situation: false},
      {id: 5, Cname: 'co5', Cduration: 90 , situation: false},
      {id: 6, Cname: 'co6', Cduration: 889 , situation: true},
      {id: 7, Cname: 'co7', Cduration: 78 , situation: false},
    ];

  getdata() {
    return this.courses;
  }
}
