import { Component, OnInit } from '@angular/core';
import { CourseServises } from './courses-services';

// وارد کردن کلاس به یک کلاس دیگر به صورت tightly coupled

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent {
  Courses;
  isActive = false;
  title = 'Courses List with tightly coupled';
  textfill = 'mvm@MVM.com';
  message;

  ImgUrl = '../../assets/1.jpg';

  constructor() {
    const Service = new CourseServises();
    this.Courses = Service.getdata();
  }

  ondivclick($event) {
    this.isActive = !this.isActive;
    if (this.Courses === null) {
      const Service = new CourseServises();
      this.Courses = Service.getdata();
    } else {
      this.Courses = null;
    }
    console.log($event);
  }

  oninputent() {
    this.textfill = null;
    this.message = 'message was sent';
  }

  inputchange() {

      this.message = null;
    }
}
